<template>
  <div>
    <div v-if="messages.length > 0">
      <message-list-row v-for="(message, key) in messages" :key="key" :message="message" />
    </div>
    <div v-else>
      <div class="no-data">
        Non hai nessuna chat attiva...
      </div>
    </div>
    <img src="/img/risorse/icon-plus-new.svg" class="new-chat-btn" @click="$router.push('/messages/users')">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MessageListRow from '@/components/Messages/MessageListRow.vue'

export default {
  components: { MessageListRow },

  computed: {
    ...mapGetters({
      messages: 'messages/items'
    })
  },

  created () {
    this.$emit('title', 'Chat')
    this.fetchMessages(Object.assign({ sync: true }))
  },

  methods: {
    ...mapActions({
      fetchMessages: 'messages/fetch'
    })
  }
}
</script>

<style scoped lang="scss">
.days-row{
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.new-chat-btn {
  padding: 10px;
  text-align: right;
  width: 70px;
  position: absolute;
  bottom: 75px;
  right: 6px;
}
.no-data{
  font-size: 14px;
  line-height: 18px;
  color: #646363;
  padding: 20px;
}
</style>
