<template>
  <div>
    <div ref="bottomEl">
      <div v-if="messages && messages.data" class="messages-container">
        <message-row
          v-for="(messageData, key) in messages.data"
          :key="key"
          :message="messageData"
          :last="key === messages.data.length -1"
          :new-message="newMessage"
          @scrollBottom="scrollBottom"
        />
      </div>
    </div>
    <div class="new-message-container">
      <div class="new-message-text">
        <base-input
          v-model="message"
          required
          placeholder="Messaggio"
          type="text"
          class="new-message-input"
        />
      </div>
      <div v-if="isLoading" v-loading="true" class="red-spinner-small" />
      <img v-else src="/img/risorse/ico-send-message.svg" @click="submit">
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MessageRow from '@/components/Messages/MessageRow.vue'

export default {
  components: { MessageRow },
  data () {
    return {
      isLoading: false,
      message: null,
      apiErrors: [],
      newMessage: false
    }
  },

  computed: {
    ...mapGetters({
      messages: 'messages/single'
    })
  },

  created () {
    this.userId = this.$route.params.userId
    this.getMessages(this.userId).then((res) => {
      this.$emit('title', this.messages.user)
      if (this.$refs.bottomEl) {
        this.$refs.bottomEl.scrollIntoView({ behavior: 'instant', block: 'end' })
      }
    })
  },

  mounted () {
    window.addEventListener('message', this.pushNotificationListener)
  },

  beforeDestroy () {
    this.clearMessages()
    window.removeEventListener('message', this.pushNotificationListener)
  },

  methods: {
    ...mapActions({
      getMessages: 'messages/get',
      clearMessages: 'messages/resetSingle',
      storeMessage: 'messages/create'
    }),
    submit () {
      if (this.message) {
        this.isLoading = true
        this.storeMessage({ payload: { receiver_id: this.userId, message: this.message } }).then((res) => {
          this.isLoading = false
          this.message = null
          this.getMessages(this.userId).then((res) => {
            this.$refs.bottomEl.scrollIntoView({ behavior: 'smooth', block: 'end' })
          })
        }).catch((err) => {
          if (err.response.status === 422 || err.response.status === 401) {
            this.apiErrors = [err.response.data.message]
            this.isLoading = false
          }
        })
      }
    },
    pushNotificationListener (e) {
      if (e.data.type === 'PushNotification') {
        if (e.data.data.additionalData.type === 'new_chat_message' && this.$route.params.userId === e.data.data.additionalData.senderId) {
          this.newMessage = true
          this.getMessages(this.userId).then((res) => {
            this.$refs.bottomEl.scrollIntoView({ behavior: 'smooth', block: 'end' })
          })
        }
      }
    },
    scrollBottom () {
      this.$refs.bottomEl.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
}
</script>

<style lang="scss">
.messages-container {
  padding-bottom: 90px;
  margin-bottom: -50px;
}
.new-message-container {
  position: fixed;
  bottom: 55px;
  padding: 0 8px;
  display: inline-flex;
  width: 100%;
  background-color: white;

  .new-message-text {
    flex-basis: calc(100% - 45px);
    margin-top: 8px;
  }

  .new-message-input {
    margin-bottom: 6px;
    margin-left: 4px;
  }

  IMG {
    width: 40px;
    flex-basis: 40px;
    margin-bottom: 2px;
    padding: 4px 4px 4px 8px;
  }
}
.red-spinner-small {
  .el-loading-spinner {
    margin-top: -14px;
    margin-left: 10px;
    .circular {
      width: 30px;
      height: 30px;
    }
    .path {
      stroke: #004182 !important;
    }
  }
}
</style>
