<template>
  <div v-if="speaker" class="speaker-wrapper">
    <div class="speaker-info-container">
      <speaker-image :speaker="speaker" />
      <div class="name">
        {{ speaker.name }} {{ speaker.surname }}
      </div>
      <div v-if="speaker.company" class="country">
        {{ speaker.company }}
      </div>
      <div v-if="speaker.user" class="link-container">
        <div
          v-if="speaker.user.chat_consent"
          class="link-button dx"
          @click="$router.push(`/messages/${speaker.user.id}`)"
        >
          CHAT
        </div>
      </div>
      <div class="description" v-html="speaker.short_bio" />
    </div>
    <div
      v-if="speaker.sessions_with_speech && speaker.sessions_with_speech.length > 0"
      class="speeches-info-container"
    >
      <div class="faculty">
        Sessioni
      </div>
      <div>
        <session-row
          v-for="(session, key) in speaker.sessions_with_speech"
          :key="key"
          :session="session"
          :hide-date=false
          @onNoteClick="handleNoteClick(session)"
          @onFavouriteClick="toggleFavourite(session, $event)"
          @onRowClick="$router.push(`/sessions/${session.id}`)"
        />
      </div>
    </div>
    <div
      v-if="speaker.sessions && speaker.sessions.length > 0"
      class="speeches-info-container"
    >
      <div class="faculty">
        Sessioni come Moderatore
      </div>
      <div>
        <session-row
          v-for="(session, key) in speaker.sessions"
          :key="key"
          :session="session"
          :hide-date=false
          @onNoteClick="handleNoteClick(session)"
          @onFavouriteClick="toggleFavourite(session, $event)"
          @onRowClick="$router.push(`/sessions/${session.id}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import SpeakerImage from '@/components/Speakers/SpeakerImage.vue'
import SessionRow from '@/components/Sessions/SessionRow.vue'

export default {
  components: { SessionRow, SpeakerImage },

  computed: {
    ...mapGetters({
      speaker: 'speakers/single',
      user: 'auth/user'
    }),

    speakerImageStyle () {
      return `background: url("${this.speaker.profile_image.full_url}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;`
    }
  },

  created () {
    this.$emit('title', 'Faculty')
    this.speakerId = this.$route.params.id
    this.getSpeaker(this.speakerId)
  },

  beforeDestroy () {
    this.clearSpeaker()
  },

  methods: {
    ...mapActions({
      getSpeaker: 'speakers/get',
      clearSpeaker: 'speakers/resetSingle'
    }),

    handleNoteClick (session) {
      if (session.note && session.note.id) {
        this.$router.push(`/my-notes/${session.note.id}`)
      } else {
        this.$router.push(`/my-notes/new?session_id=${session.id}`)
      }
    },

    toggleFavourite (session, isFavourite) {
      if (!this.user) {
        this.$router.push({ name: 'Login', query: { redirect: `/sessions/${session.id}` } })
      } else {
        const favourite = isFavourite ? 1 : 0
        axios.put(`/user/${session.id}/favourite-session/${favourite}`).then((response) => {
          this.getSpeaker(this.speakerId)
        }, () => {})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.speaker-wrapper{
  margin-bottom: 30px;
  .speaker-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    .name{
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
    }

    .country{
      /*font: normal normal normal 14px/18px Suisse Intl;*/
      /*font-family: Suisse Intl;*/
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.14px;
      color: #646363;
      margin-bottom: 20px;
      text-align: center;
    }

    .description{
      /*font: normal normal normal 14px/18px Suisse Intl;*/
      /*font-family: Suisse Intl;*/
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.14px;
      color: #646363;
      margin-bottom: 0px;
      padding: 20px;
    }
    .link-container {
      text-align: center;
      margin-bottom: 10px;
    }
    .link-button {
      width: 200px;
      height: 40px;
      text-align: center;
      margin-top: 10px;
      color: white;
      font-weight: bold;
    }
    .link-button.sx {
      background-color: #102785;
      padding-top: 10px;
    }
    .link-button.dx {
      background-color: #52b729;
      padding-top: 10px;
    }
  }

  .speeches-info-container {
    margin-bottom: 0px;
  }

  .faculty{
    /*font-family: Suisse Intl;*/
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.14px;
    color: #000;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    padding: 20px;
  }
}
</style>
