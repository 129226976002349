<template>
  <div>
    <note-row
      v-for="(note, key) in notes"
      :key="key"
      :note="note"
      @onRowClick="$router.push(`/my-notes/${note.id}`)"
    />
    <div class="new-note-button-container">
      <img src="/img/risorse/icon-plus-new.svg" class="new-note-btn" @click="newNote">
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NoteRow from '@/components/Notes/NoteRow.vue'

export default {
  components: { NoteRow },

  computed: {
    ...mapGetters({
      notes: 'notes/items'
    })
  },

  created () {
    this.$emit('title', 'Mie Note')
    this.fetchNotes({
      sort: '-updated_at'
    })
  },

  methods: {
    ...mapActions({
      fetchNotes: 'notes/fetch'
    }),
    newNote () {
      this.$router.push('/my-notes/new')
    }
  }
}
</script>

<style lang="scss">
.new-note-btn {
  padding: 10px;
  text-align: right;
  width: 70px;
  position: absolute;
  bottom: 75px;
  right: 6px;
}
</style>
