<template>
  <div class="qr-container">
    <h3 style="text-align: center;">
      SCANSIONA I BADGE
    </h3>
    Ora puoi scansionare il codice QR sul badge degli altri partecipanti per raccogliere le loro informazioni di contatto e restare in contatto con loro!
    <br>
    <br>
    Basta premere il pulsante qui sotto: si aprirà la fotocamera del tuo telefono e dovrai semplicemente puntarla verso un codice QR; le informazioni rimarranno nella lista qui sotto per tua comodità.
    <br>
    <br>
    <br>
    <div style="width: 100%; text-align: center">
      <base-button class="mb-2" size="lg" @click="windowParent.postMessage({ type: 'scanQR' }, '*')">
        Avvia la scansione di un badge
      </base-button>
    </div>
    <div v-if="scanUsers.length" class="scan-history">
      <b>Archivio scansioni:</b><br>
      <div v-for="row in scanUsers" :key="row.id" class="scan-row">
        Nome:
        {{ row.user.first_name }} {{ row.user.last_name }}
        <br>
        E-mail:
        {{ row.user.email }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      scannedData: {
        first_name: null,
        last_name: null,
        email: null
      },
      scanned: false,
      apiErrors: []
    }
  },

  computed: {
    ...mapGetters({
      scanUsers: 'scanUsers/items'
    })
  },

  created () {
    this.$emit('title', 'Networking')
    this.thisWindow.addEventListener('message', this.messageEventListener)
    this.fetchScanUsers(Object.assign({ sync: true }))
  },

  beforeDestroy () {
    this.thisWindow.removeEventListener('message', this.messageEventListener)
    this.clearScanUsers()
  },

  methods: {
    ...mapActions({
      fetchScanUsers: 'scanUsers/fetch',
      clearScanUsers: 'scanUsers/resetItems'
    }),
    messageEventListener (e) {
      if (e.data.type === 'QRScanContent') {
        // console.log('Vue scanned: ' + e.data.data)
        this.scanQR(e.data.data)
      }
    },
    scanQR (qrcode) {
      // console.log('starting scanQR ' + qrcode)
      this.getScannedData(qrcode).then((res) => {
        this.fetchScanUsers(Object.assign({ sync: true }))
      }).catch((err) => {
        // console.log(err)
        if (err.response.status === 422 || err.response.status === 401) {
          this.apiErrors = [err.response.data.message]
        };
      })
    },
    getScannedData (qrcode) {
      return new Promise((resolve, reject) => {
        axios.get('/user/scanQR/' + qrcode).then((response) => {
          this.scannedData.first_name = response.data.content.first_name
          this.scannedData.last_name = response.data.content.last_name
          this.scannedData.email = response.data.content.email
          this.scannedData.company = response.data.content.company
          this.scanned = true
          resolve(response)
        }, (error) => {
          reject(error)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.qr-container {
    padding: 12px;
}
.last-scan {
    background-color: #e0e0e0;
    padding: 8px;
    margin-top: 30px;
}
.scan-history {
    margin-top: 30px;
}
.scan-row {
    background-color: #e0e0e0;
    padding: 8px;
    margin: 2px;
}
</style>
