<template>
  <div class="notification-row" @click="gotonotification">
    <div class="notification-container">
      <div class="notification-title-container">
        <div class="notification-title">
          <div v-if="!notification.read" class="unread" />
          {{ notification.title }}
        </div>
      </div>
      <div class="notification-details-container">
        <div class="notification-message">
          {{ notification.message_notags }}
        </div>
      </div>
      <div class="notification-datetime-container">
        <div class="datetime">
          {{ notification.created_at | formatDateTime }}
        </div>
      </div>
    </div>
    <div class="notification-icon">
      <img src="/img/risorse/arrow-right-blue-new.svg">
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    formatDateTime (value) {
      const date = new Date(value)
      const minutes = date.getMinutes()
      const hours = date.getHours()
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('IT', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)} - ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },

  props: {
    notification: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  methods: {
    gotonotification () {
      if (this.notification.type === 'favourite_speech') {
        this.$router.push(`/speeches/${this.notification.object_id}`)
      } else if (this.notification.type === 'new_chat_message') {
        this.$router.push(`/messages/${this.notification.object_id}`)
      } else if (this.notification.type === 'speech_starting') {
        this.$router.push(`/speeches/${this.notification.object_id}`)
      } else if (this.notification.type === 'broadcast_message') {
        this.$router.push(`/notifications/${this.notification.id}`)
      }
    }
  }
}
</script>

<style lang="scss">
.notification-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:nth-of-type(odd) {
    background-color: #F3F3F3;
  }

  .notification-container {
    flex: 1;
    padding: 10px 20px;
    min-width: 0;
  }

  .notification-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 0;
      >div {
          display: flex;
          align-items: center;
      }
      .notification-title {
          font-size: 14px;
          font-weight: 600;
          color: #000;
          height: 24px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          min-width: 0;
      }
      IMG {
          width: 30px;
          max-width: 30px;
          padding: 4px;
      }
  }
  .unread {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: green;
    margin-right: 6px;
  }
  .notification-details-container {
    display: flex;
    justify-content: space-between;
    color: #646363;
    min-width: 0;
    .notification-message{
      height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-width: 0;
    }
    .notification-room{
      font-size: 14px;
    }
    .notification-to {
      font-size: 14px;
    }
  }
  .notification-datetime-container{
    .datetime{
      font-style: italic;
      font-size: 11px;
    }
  }
  .notification-icon {
    padding: 20px;
  }
}
.notification-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
