<template>
  <div class="search-container">
    <div class="search-text">
      <base-input
        v-model="inputVal"
        required
        :placeholder="placeholder"
        type="text"
        class="search-input"
      />
    </div>
    <img src="/img/risorse/ico-search.svg">
  </div>
</template>
<script>
export default {
  name: 'SearchBar',

  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Cerca'
    }
  },

  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss">
.search-container {
  padding: 10px 15px 15px;
  height: 65px;
  width: 100%;
  position: absolute;
  display: flex;
  background: #fff;
  align-items: center;
}
.search-container .search-text {
  flex-grow: 1;
}

.search-container .form-group {
  margin-right: 15px;
  margin-bottom: 0px;
}

.search-container img {
  width: 30px;
  margin-right: 5px;
}
</style>
