<template>
  <div>
    <div class="settings-container">
      <base-button class="mb-2" size="lg" @click="windowParent.postMessage({ type: 'forceNetworkOnline' }, '*')">
        Abilita ONLINE
      </base-button>
      <base-button class="mb-2" size="lg" @click="windowParent.postMessage({ type: 'forceNetworkOffline' }, '*')">
        Abilita OFFLINE
      </base-button>
      <base-button class="mb-2" size="lg" @click="windowParent.postMessage({ type: 'disableNetworkOfflineCheck' }, '*')">
        Disabilita ONLINE/OFFLINE
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SponsorRow from '@/components/Sponsors/SponsorRow.vue'

export default {

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters({}),
  },

  created () {
    this.$emit('title', 'Settings')
  },

  methods: {
    ...mapActions({
    })
  }
}
</script>

<style scoped lang="scss">
</style>
