<template>
  <div class="sponsor-wrapper">
    <div v-if="sponsor" class="sponsor-info-container">
      <sponsor-image :sponsor="sponsor" />
      <div class="name">
        {{ sponsor.name }}
      </div>
      <div class="description" v-html="sponsor.description" />
      <div v-if="sponsor.website" class="go-to-sponsor-container">
        <div><b>Website:</b></div>
        <p class="link" @click="goToSponsor">
          {{ sponsor.website }}
        </p>
      </div>
      <div v-if="sponsor.attachments.length" class="sponsor-attachments">
        <div><b>Downloads:</b></div>
        <div v-for="(attachment, key) in sponsor.attachments" :key="key" class="link" @click="goToSponsorAttachment(attachment)">
          {{ attachment.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SponsorImage from '@/components/Sponsors/SponsorImage.vue'
export default {
  components: { SponsorImage },

  computed: {
    ...mapGetters({
      sponsor: 'sponsors/single'
    }),
    speakerImageStyle () {
      return `background: url("${this.sponsor.logo.full_url}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;`
    }
  },

  created () {
    this.$emit('title', 'Sponsor')
    this.sponsorId = this.$route.params.id
    this.getSponsor(this.sponsorId)
  },

  beforeDestroy () {
    this.clearSponsor()
  },

  methods: {
    ...mapActions({
      getSponsor: 'sponsors/get',
      clearSponsor: 'sponsors/resetSingle'
    }),
    goToSponsor () {
      if (this.sponsor.website.startsWith('http')) {
        window.open(this.sponsor.website, '_blank')
      } else {
        window.open(`https://${this.sponsor.website}`, '_blank')
      }
    },
    goToSponsorAttachment (attachment) {
      // const url = 'https://api.cliacruiseweek.com/uploads/' + attachment.path
      // window.open(attachment.full_url, '_system')
      this.windowParent.postMessage({ type: 'openInAppBrowser', url: attachment.full_url }, '*')
    }
  }
}
</script>

<style scoped lang="scss">
.sponsor-wrapper{
  .sponsor-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .name{
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
    }

    .description{
      /*font: normal normal normal 14px/18px Suisse Intl;*/
      /*font-family: Suisse Intl;*/
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.14px;
      color: #646363;
      margin-bottom: 20px;
      padding: 20px;
    }

    .go-to-sponsor-container{
      display: block;
      text-align: center;
      margin-bottom: 20px;

      .link{
        text-decoration: underline;
        color: #000;
        padding: 4px;
      }
    }
    .sponsor-attachments {
      display: block;
      text-align: center;
      margin-bottom: 20px;

      .link{
        text-decoration: underline;
        color: #000;
        padding: 4px;
      }
    }
  }
}
</style>
