<template>
  <div v-if="notification" class="notification-wrapper">
    <div class="notification-info-container">
      <div class="title">
        {{ notification.title }}
      </div>
      <div class="datetime">
        {{ notification.created_at | formatDateTime }}
      </div>
      <div class="description" v-html="notification.message" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  filters: {
    formatDateTime (value) {
      const date = new Date(value)
      const minutes = date.getMinutes()
      const hours = date.getHours()
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('IT', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)} - ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },

  computed: {
    ...mapGetters({
      notification: 'notifications/single'
    })
  },

  created () {
    this.$emit('title', 'Notifiche')
    this.notificationId = this.$route.params.id
    this.getNotification(this.notificationId)
  },

  beforeDestroy () {
    this.clearNotification()
  },

  methods: {
    ...mapActions({
      getNotification: 'notifications/get',
      clearNotification: 'notifications/resetSingle'
    })
  }
}
</script>

<style scoped lang="scss">
.notification-wrapper{
  .notification-info-container{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
      margin: 8px 8px 16px 8px;
    }

    .datetime{
      font-style: italic;
      font-size: 11px;
      text-align: left;
      width: 99%;
      padding-left: 4px;
      color: #646363;
    }

    .description  {
      padding: 8px;
      color: #646363;
    }
  }
}
</style>
