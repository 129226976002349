<template>
  <div class="social-event-row" @click="$router.push(`/social-events/${socialEvent.id}`)">
    <div class="social-event-info-container">
      <div class="social-event-title-container">
        <b>{{ socialEvent.title }}</b>
      </div>
      <div class="social-event-date-container">
        {{ socialEvent.date | formatDate }} - {{ socialEvent.date | formatTime }}
      </div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  filters: {
    formatDate (value) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('IT', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)}`
    },

    formatTime (value) {
      const date = new Date(value)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },

  props: {
    socialEvent: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.social-event-row{
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  align-items: center;
  column-gap: 10px;

  .social-event-title-container {
    color: #000;
  }

  .social-event-info-container{
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .social-event-icon-container{
    width: 150px;
    max-height: 50px;
  }

  .social-event-date-container{
    margin-top: 10px;
  }
}
.social-event-row:active {
  background-color: #c0c0c0;
  border: 1px solid #a1a1a1;
}
</style>
