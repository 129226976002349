<template>
  <div class="message-row" @click="$router.push(`/messages/${message.id}`)">
    <div class="labels-container">
      <div class="user-name">
        <b>{{ message.first_name }} {{ message.last_name }}</b> {{ message.company }}
      </div>
      <div class="last-message">
        {{ message.last_message }}
      </div>
      <div class="datetime">
        {{ message.last_message_time | formatDateTime }}
      </div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  filters: {
    formatDateTime (value) {
      const date = new Date(value)
      const minutes = date.getMinutes()
      const hours = date.getHours()
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('IT', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },

  props: {
    message: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.message-row{
  padding: 10px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    margin-left: 0;
    margin-right: 8px;
    flex: 1;
    color: #808080;
    min-width: 0;
  }
  .datetime {
    font-style: italic;
    font-size: 11px;
  }
  .user-name {
    height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
  }
  .last-message {
    font-size: 14px;
    height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
  }
}
.message-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
