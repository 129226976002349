<template>
  <div class="map-container">
    Fai click sulla piantina per ingrandirla
    <br><br>
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/area_espositiva.jpg'), '*');">
      Area Espositiva
    </div>
    <img
      src="/img/area_espositiva.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/area_espositiva.jpg'), '*');"
    >
    <br><br>
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/piano_terra.jpg'), '*');">
      Piano Terra
    </div>
    <img
      src="/img/piano_terra.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/piano_terra.jpg'), '*');"
    >
    <br><br>
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/primo_piano.jpg'), '*');">
      Primo Piano
    </div>
    <img
      src="/img/primo_piano.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/primo_piano.jpg'), '*');"
    >
  </div>
</template>

<script>
export default {
  created () {
    this.$emit('title', 'Piantina')
  },
  methods: {
    inAppBrowserObject (path) {
      const imageUrl = `${process.env.VUE_APP_BASE_URL}${path}`
      return { type: 'openInAppBrowser', url: imageUrl }
    }
  }
}
</script>

<style scoped lang="scss">
.map-container{
  padding: 20px;
  text-align: center;
}
.floor-label {
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
}
</style>
