<template>
  <div>
    <div v-if="congressInfo" class="congress-info-container" v-html="congressInfo.description" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      staticPageKey: this.$route.params.key,
      titles: {
        'general-info': 'Info generali',
        'tourist-info': 'Info turistiche',
        'simi-presentation': 'SIMI'
        // 'myeugms-presentation': 'MyEuGMS'
      }
    }
  },

  computed: {
    ...mapGetters({
      congressInfo: 'congressInfo/single',
      user: 'auth/user'
    })
  },

  created () {
    this.$emit('title', this.titles[this.staticPageKey])
    this.getCongressInfo(this.staticPageKey)
  },

  beforeDestroy () {
    this.clearCongressInfo()
  },

  methods: {
    ...mapActions({
      getCongressInfo: 'congressInfo/get',
      clearCongressInfo: 'congressInfo/resetSingle'
    })
  }
}
</script>

<style scoped lang="scss">
.congress-info-container{
  padding: 20px;
}
</style>
