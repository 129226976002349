<template>
  <div class="next-congress-container">
    <div class="bg-next-congress">
      <img class="logo-next-congress" src="/img/eugms2025/LOGO2025-Negativo.png">
      <img class="logo-simi" src="/img/eugms2025/LOGOEuGMS-Negativo.png">
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$emit('title', 'SIMI 2025')
  }
}
</script>

<style lang="scss">
.next-congress-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 92px);

  .bg-next-congress {
    height: 100%;
    width: 100%;
    background: url(/img/eugms2025/SFONDO1250x1500.jpg);
    background-size: cover;
    background-position: left;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo-next-congress {
    position: absolute;
    width: 70%;
    top: 80px;

    @media screen and (min-width: 376px) {
      width: 80%;
      top: 110px;
    }

    @media screen and (min-width: 768px) {
      width: 60%;
    }
  }

  .logo-simi {
    position: absolute;
    width: auto;
    bottom: 80px;
    width: 40%;

    @media screen and (min-width: 376px) {
      bottom: 100px;
    }

    @media screen and (min-width: 768px) {
      width: 30%;
    }
  }

  /* .logo-next-congress {
    position: absolute;
    width: 80%;
    top: 110px;
    left: calc(50% -(80% / 2));

    @media screen and (min-width: 769px) {
      width: 60%;
      left: calc(50% -(60% / 2));
    }
  }

  .logo-simi {
    position: absolute;
    width: auto;
    bottom: 100px;
    width: 40%;
    left: calc(50% -(40% / 2));
  } */
}
</style>
