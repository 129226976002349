<template>
  <div>
    <search-bar v-model="searchText" />
    <div v-if="users" class="main-content">
      <user-row
        v-for="(user, key) in usersFiltered"
        :key="key"
        :user="user"
        @onRowClick="$router.push(`/messages/${user.id}`)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '@/components/Search/SearchBar.vue'
import UserRow from '@/components/Messages/UserRow.vue'

export default {
  components: {
    SearchBar,
    UserRow
  },

  data () {
    return {
      searchText: ''
    }
  },

  computed: {
    ...mapGetters({
      users: 'messages/users'
    }),
    usersFiltered () {
      if (this.users && this.searchText) {
        return this.users.filter(item =>
          (item.first_name && item.first_name.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (item.last_name && item.last_name.toLowerCase().includes(this.searchText.toLowerCase()))
        )
      } else {
        return this.users
      }
    }
  },

  created () {
    this.$emit('title', 'Nuova chat')
    this.fetchUsers()
  },

  methods: {
    ...mapActions({
      fetchUsers: 'messages/fetchUsers'
    })
  }
}
</script>

<style lang="scss">
.main-content {
  padding-top: 65px;
}
</style>
